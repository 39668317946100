import React, { useState, useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';

import { useMutation } from '@apollo/react-hooks';
import { FormControl, MenuItem, Select, TextField, Grid, InputLabel } from '@material-ui/core';
import {
  checkJWTExpired,
  setToken,
  getToken,
  navigate,
  parseGraphQLErrorMessage,
} from '../utils/utils';
import Layout from '../components/layout';
import { LOGIN } from '../crud';
import { ErrorDialog, LoadingButton } from '../components/common';
import sygnaLogo from '../images/logo.png';

const ERROR = {
  SESSION_EXPIRED: 'Session expired, please login again.',
  EMPTY_FORM: 'Please enter username and password.',
};

function InputComp(prop) {
  return (
    <FormControl>
      <Grid container spacing={1} alignItems="flex-end" justify="center">
        <Grid item>{prop.children}</Grid>
        <Grid item>
          <TextField
            label={prop.placeholder}
            onChange={(e) => prop.hook(e.target.value)}
            type={prop.type}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
}

function SelectComp(prop) {
  return (
    <FormControl
      style={{
        marginTop: '0.5em',
      }}
    >
      <InputLabel>Environment</InputLabel>
      <Select
        onChange={(e) => prop.hook(e.target.value)}
        value={prop.val}
        style={{
          minWidth: 200,
        }}
      >
        <MenuItem value="TEST">Test</MenuItem>
        <MenuItem value="PROD">Production</MenuItem>
      </Select>
    </FormControl>
  );
}

function RedirectIfLoggedIn({ children }) {
  // navigate to profile if there is a token
  const token = getToken();
  if (token && !checkJWTExpired(token)) {
    navigate('/profile');
    return <div />;
  }
  return children;
}

function Index() {
  // navigate to profile if there is a token
  const token = getToken();

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState('');

  const [doLogin] = useMutation(LOGIN);

  const googleResponse = async (response) => {
    try {
      setLoading(true);
      const { data } = await doLogin({
        variables: {
          oAuthToken: response.accessToken,
        },
      });
      setLoading(false);
      setDone(true);
      // login success, store to localstorage
      setToken(data.login);
      // push to profile(? page
      navigate('/profile');
    } catch (err) {
      setLoading(false);
      // login failed, show error message
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(err));
    }
  };

  const googleFail = (res) => {
    setLoading(false);
    setErrorDialogOpen(true);
    setError(res.error);
  };

  useEffect(() => {
    const expireError = token && checkJWTExpired(token);
    if (expireError) {
      setToken();
      setErrorDialogOpen(true);
      setError(ERROR.SESSION_EXPIRED);
    }
  }, [token]);

  return (
    <Layout>
      <RedirectIfLoggedIn>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={1}
          style={{ marginTop: '12em' }}
        >
          <Grid>
            <img id="logo" src={sygnaLogo} alt="Sygna Bridge Logo" />
          </Grid>
          <Grid>
            <h2>Back Office</h2>
          </Grid>
          <Grid>
            <GoogleLogin
              clientId={
                process.env.GATSBY_SSO_ID ||
                '40385882013-hj10q6j8up2k9l0tpko16at0vmfhgdk3.apps.googleusercontent.com'
              }
              render={(renderProps) => (
                <LoadingButton
                  loading={loading}
                  disabled={renderProps.disabled}
                  onClick={renderProps.onClick}
                  done={{ get: done, set: setDone }}
                  buttonType="submit"
                  buttonStyle={{ width: '18em' }}
                  buttonText="Login With Google"
                  timeout={3000}
                />
              )}
              onSuccess={googleResponse}
              onFailure={googleFail}
              key="googleAuthButton"
            />
          </Grid>
        </Grid>
      </RedirectIfLoggedIn>
      <ErrorDialog open={{ get: errorDialogOpen, set: setErrorDialogOpen }} message={error} />
    </Layout>
  );
}

export default Index;
export { InputComp, ERROR, SelectComp };
